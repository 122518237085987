import React, { Component } from 'react';

import ModuleText from '../modules/ModuleText';
import ModuleQuote from '../modules/ModuleQuote';
import ModuleFullAsset from '../modules/ModuleFullAsset';
import ModuleTextVideo from '../modules/ModuleTextVideo';
import ModuleTextImage from '../modules/ModuleTextImage';
import ModuleAdPlatform from '../modules/ModuleAdPlatform';
import ModuleMediaGallery from '../modules/ModuleMediaGallery';
import ModuleDoubleAsset from '../modules/ModuleDoubleAsset';

class AdsModule extends Component {
    render() {
        let modulesArr = [];
        const { modules, specialcss } = this.props;
       
        modules && (
            modules.forEach(function(item) {
                // Check if contentType param exists, if it doesn't that mean the article was published with a module that is still in draft state.
                if (item.sys.contentType) {
                    if (item.sys.contentType.sys.id === 'textPlusModule') {
                        console.log('Text Plus Module!', item);
                        if (item.fields.sidebarContentType === 'Video') {
                            modulesArr.push(
                                <ModuleTextVideo
                                    showHorizontalSeparator={item.fields.showHorizontalSeparator}
                                    hideRedBar={item.fields.hideRedBar}
                                    videoID={item.fields.vimeoID}
                                    bodyCopy={item.fields.body}
                                    caption={item.fields.caption}
                                    layout={item.fields.layout} />
                            )
                        } else if (item.fields.sidebarContentType === 'Image') {
                            modulesArr.push(
                                <ModuleTextImage
                                    specialcss={specialcss}
                                    showHorizontalSeparator={item.fields.showHorizontalSeparator}
                                    hideRedBar={item.fields.hideRedBar}
                                    layout={item.fields.layout}
                                    image={item.fields.image.fields.file.url}
                                    caption={item.fields.caption}
                                    bodyCopy={item.fields.body} />
                            )
                        } else if (item.fields.sidebarContentType === 'Snippet' || item.fields.sidebarContentType === 'Pull Quote') {
                            modulesArr.push(
                                <ModuleQuote
                                    showHorizontalSeparator={item.fields.showHorizontalSeparator}
                                    contentType={item.fields.sidebarContentType}
                                    layout={item.fields.layout}
                                    body={item.fields.body}
                                    snippet={item.fields.snippet}
                                    pullQuote={item.fields.pullQuote}
                                    attribution={item.fields.attribution} />
                            );
                        }  else if (item.fields.sidebarContentType === 'None') {
                            modulesArr.push(
                                <ModuleQuote
                                    showHorizontalSeparator={item.fields.showHorizontalSeparator}
                                    contentType={item.fields.sidebarContentType}
                                    layout={item.fields.layout}
                                    body={item.fields.body}
                                    snippet={item.fields.snippet}
                                    pullQuote={item.fields.pullQuote}
                                    attribution={item.fields.attribution} />
                            );
                        }
                    } else if (item.sys.contentType.sys.id === "fullWidthAssetModule") {
                        if (item.fields.assetType) {
                            modulesArr.push(
                                <ModuleFullAsset
                                    heading={item.fields.heading}
                                    assetType={item.fields.assetType}
                                    videoID={item.fields.vimeoID}
                                    caption={item.fields.caption}
                                    hideRedBar={item.fields.hideRedBar} />
                            );
                        } else {
                            modulesArr.push(
                                <ModuleFullAsset
                                heading={item.fields.heading}
                                    assetType={item.fields.assetType}
                                    title={item.fields.title}
                                    image={item.fields.asset.fields.file.url}
                                    caption={item.fields.caption}
                                    hideRedBar={item.fields.hideRedBar} />
                            );
                        }
                    } else if (item.sys.contentType.sys.id === 'textModule') {
                        modulesArr.push(
                            <ModuleText
                                position={item.fields.position}
                                width={item.fields.width}
                                bodyCopy={item.fields.bodyCopy}
                            />
                      
                        )
                    } else if (item.sys.contentType.sys.id === 'adSolutionAvails') {
                        modulesArr.push(
                            <ModuleAdPlatform item={item}/>

                        );
                    } else if (item.sys.contentType.sys.id === 'gallery') {
                        modulesArr.push(
                            <ModuleMediaGallery item={item}/>
                        );
                    } else if (item.sys.contentType.sys.id === 'doubleAssetModule') {
                        modulesArr.push(
                            <ModuleDoubleAsset item={item}/>
                        );
                    }
                }
            })
        )

        return (
            <section className="section__ads-single">
                <div className="container">
                    {modulesArr}
                </div>
            </section>
          );
    }
}

export default AdsModule;
