import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';


import ReactGA from 'react-ga';

import ModuleFullAsset from '../modules/ModuleFullAsset';
import ModuleQuote from '../modules/ModuleQuote';
import ModuleText from '../modules/ModuleText';
import ModuleTextVideo from '../modules/ModuleTextVideo';
import ModuleTextImage from '../modules/ModuleTextImage';
import ModuleVideoSnippet from '../modules/ModuleVideoSnippet';
import ModuleFastFacts from '../modules/ModuleFastFacts';
import ModuleDoubleAsset from '../modules/ModuleDoubleAsset';

import CaseStudyGridNew from './CaseStudyGridNew';

let singleContent;
var contentful = require('contentful');

var client = contentful.createClient({
  space: 'purcre4qggd1',
  environment: 'staging',
  accessToken: 'vdIxkgnr7AFvqwzg7XFaqNaI9K4LmXOwzuvAquQOxok'
});

class CaseStudySingle extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	    	apiFail: false,
	    	brand: '',
	    	heading: '',
	    	logoUrl: '',
	    	highlights: [],
	    	modules: [],
	    	tags: [],
	    	fastFacts: [],
	    	caseStudies: [],
	    	catEditorial: [],
	    };
	}

	componentDidMount() {
		singleContent = window.location.pathname;
		singleContent = singleContent.substring(singleContent.lastIndexOf('/')+1);

		// Get Single Content
		client.getEntries({
			content_type: 'caseStudyPage',
	    	'fields.slug': singleContent,
	    	include: 10,
	    })
	    .catch(error => {
	      console.log(error);
	    }).then(data => {
	    	if (data) {
	    		// IF API CALL RETURNS 0 INSTEAD OF A JS ERROR 
	    		// WE SHOULD THROW A 404
	    		if (data.total !== 0) {
		    		let dataPath = data.items[0].fields;
		    		console.log(dataPath);

		    		document.title =  dataPath.title + ' | Case Study | Warner Bros. Discovery';

		    		// Top-level Page Tracking
					ReactGA.set({ page: '/case-studies/' + singleContent }); // Update the user's current page
	  	    		ReactGA.pageview('/case-studies/' + singleContent);

		    		this.setState({
		    			brand: dataPath.title,
		    			heading: dataPath.heading,
		    			logoUrl:  dataPath.logo.fields.file.url,
		    			highlights: dataPath.highlights || [],
		    			introCopy: dataPath.intro,
		    			modules: dataPath.modules,
		    			fastFacts: dataPath.keyResults,
		          		tags: dataPath.tags,
		        	});

		        	if (dataPath.caseStudiesGrid) {
		        		console.log('Exissts');
		        		this.setState({
			          		caseStudies: dataPath.caseStudiesGrid.fields,
		        		});
		        	} else {
		        		console.log('Nope');
		        		this.setState({
			          		caseStudies: [],
		        		});
		        	}
		        } else {
		    		this.setState({
	    				apiFail: true,
	    			})
		    	}
	    	}
	    });
	}

	redirectPage = () => {
		if (this.state.apiFail) {
			return <Redirect to='/404' />
		}
	}

	render() {
		let modulesArr = [];
		const {tags, brand, heading, logoUrl, fastFacts, introCopy, highlights, modules, caseStudies } = this.state;

		if (modules) {
			modules.forEach(function(item) {
				// Check if contentType param exists, if it doesn't that mean the article was published with a module that is still in draft state.
				if (item.sys.contentType) {
					if (item.sys.contentType.sys.id === 'textPlusModule') {
						// console.log('Text Plus Module!', item);
						if (item.fields.sidebarContentType === 'Video') {
							modulesArr.push(
								<div className="container">
									<ModuleTextVideo
										hideRedBar={item.fields.hideRedBar}
										showHorizontalSeparator={item.fields.showHorizontalSeparator}
										videoID={item.fields.vimeoID}
										bodyCopy={item.fields.body}
										caption={item.fields.caption}
										layout={item.fields.layout}
									/>
								</div>
							)
						} else if (item.fields.sidebarContentType === 'Image') {
							modulesArr.push(
								<div className="container">	
									<ModuleTextImage
										showHorizontalSeparator={item.fields.showHorizontalSeparator}
										hideRedBar={item.fields.hideRedBar}
										layout={item.fields.layout}
										image={item.fields.image.fields.file.url}
										caption={item.fields.caption}
										bodyCopy={item.fields.body} />
								</div>
							)
						} else if (item.fields.sidebarContentType === 'Snippet' || item.fields.sidebarContentType === 'Pull Quote') {
			 				modulesArr.push(
			 					<div className="container">
			 						<ModuleQuote
			 							showHorizontalSeparator={item.fields.showHorizontalSeparator}
				 						contentType={item.fields.sidebarContentType}
				 						layout={item.fields.layout}
										body={item.fields.body}
										snippet={item.fields.snippet}
										pullQuote={item.fields.pullQuote}
										attribution={item.fields.attribution} />
								</div>
							);
			 			}
					} else if (item.sys.contentType.sys.id === "fullWidthAssetModule") {
			 			if (item.fields.assetType) {
			 				modulesArr.push(
				 				<div className="container">
				 					<ModuleFullAsset
				 						heading={item.fields.heading}
				 						hideRedBar={item.fields.hideRedBar}
					 					assetType={item.fields.assetType}
					 					videoID={item.fields.vimeoID}
					 					caption={item.fields.caption} />
				 				</div>
				 			);
			 			} else {
			 				modulesArr.push(
			 					<div className="container">
				 					<ModuleFullAsset
				 						heading={item.fields.heading}
				 						hideRedBar={item.fields.hideRedBar}
					 					assetType={item.fields.assetType}
					 					title={item.fields.title}
					 					image={item.fields.asset.fields.file.url}
					 					caption={item.fields.caption} />
				 				</div>
			 				);
			 			}
			 		} else if (item.sys.contentType.sys.id === 'textModule') {
						modulesArr.push(
							<div className="container">
			                    <ModuleText
			                        position={item.fields.position}
			                        width={item.fields.width}
			                        bodyCopy={item.fields.bodyCopy}
			                    />
			                </div>
		              
		                )
					} else if (item.sys.contentType.sys.id === "videoModuleWithSidebarSnippet" ) {
						modulesArr.push(
							<div className="container">
								<ModuleVideoSnippet
									videoID={item.fields.videoUrl}
									caption={item.fields.caption}
									snippet={item.fields.snippet}
								/>
							</div>
						);
					} else if (item.sys.contentType.sys.id === "doubleAssetModule" ) {
						modulesArr.push(
							<div className="container">
								<ModuleDoubleAsset
									item={item} />
							</div>
						);
					}
				}
			});
		}

		return (
		    <div className="case-single">
		    {this.redirectPage()}
		    	<div className="container">
		    		<div className="case-single__tags">
		    			{tags && (
		    				<div>
				    			{tags.map((item, index) => (
				    				<Link to={`/search?term=${item}`}><h6 key={index}>{item}</h6></Link>
				    			))}
			    			</div>
			    		)}
			    	</div>
		    		<header className="case-single__header">
			    		<div className="case-single__header--logo">
			    			<img src={logoUrl} alt={`Logo - ${brand}`} /> 
			    		</div>
				    	<h1 className="case-single__header--headline">{heading}</h1>
				    </header>

			    	<div className="case-single__intro">
			    		<div className="case-single__intro--left">
			    			<div dangerouslySetInnerHTML={{ __html: introCopy }}/>
			    		</div>
			    		<div className="case-single__intro--right">
			    			<ul className="case-single__highlights">
				    			{highlights.map((item, index) => (
				    				<li key={index}><h5>{item}</h5></li>
				    			))}
				    		</ul>
			    		</div>
			    	</div>
				</div>

				<section className="case-single__mods">
					{modulesArr}
				</section>

				{fastFacts && (
					<ModuleFastFacts
						linebreak={true}
						background="white"
						item={fastFacts}/>
				)}
				{caseStudies && (
				caseStudies.caseStudies && (
					<div>
						<div className="container">
							<h3 className="case-single__headline margin-side-md">Case Studies</h3>
						</div>

						<div className="case-single__cs">
							<CaseStudyGridNew
							grid={caseStudies}/>
						</div>
					</div>
				)
				)}
			</div>
		
			
		  );
	}
}

export default CaseStudySingle;

