import React, { Component } from 'react';
import ReactGA from 'react-ga';

import ContactForm from './utility/ContactForm';

var contentful = require('contentful')

var client = contentful.createClient({
  space: 'purcre4qggd1',
  environment: 'staging',
  accessToken: 'vdIxkgnr7AFvqwzg7XFaqNaI9K4LmXOwzuvAquQOxok'
});

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      links: [],
    };

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    client.getEntries({
      content_type: 'siteFooter',
      // 'fields.slug': '/',
      'include': 10
    }).then(data => {
      if (data) {
        // console.log('Footer', data);
        this.setState({
          links: data.items[0].fields.links
        });
      }
    });
  }

  handleClick(label) {
    // console.log('GA Event Label',  'Footer - ' + label)
    ReactGA.event({
      category: 'Footer',
      action: 'Click',
      label: 'Footer - ' + label
    });
  }

  render() {
    const { links } = this.state;

    return (
      <footer className="footer">
        <div className="container">
          <div className="footer__flex-wrapper">
            <div className="footer__contact">
              <div className="footer__contact--form">
                <h4 className="footer__headline">Contact Us</h4>
                <ContactForm/>
              </div>
            </div>
            <div className="footer__nav">
              <img className="footer__nav--logo" src="/assets/images/WBD_HorizontalLogo_OnDark_White.png" alt="Warner Bros. Discovery Logo"/>
              
              {links && (
                <ul className="footer__nav--list">
                  {links.map((item, index) => (
                    <li key={index}>
                      <a href={item.fields.url}
                         className="footer__nav--link"
                         target={item.fields.openInNewWindow ? '_blank' : ''}
                         rel="noopener noreferrer"
                         onClick={() => {this.handleClick(item.fields.linkText)}}>{item.fields.linkText}</a>
                    </li>
                  ))}
                </ul>
              )}
              
            </div>
          </div>
        </div>
        <div className="footer__copyright">
          <div className="container">
            <h5>&copy;2022 Warner Bros. Discovery, Inc. or its subsidiaries and affiliates. All Rights Reserved.</h5>
          </div>
        </div>
      </footer>
    );
  }
}




export default Footer;