import React, { Component } from 'react';
import ReactGA from 'react-ga';

import AdsHeader from './AdsHeader';
import AdsNav from './AdsNav';

var contentful = require('contentful')

var client = contentful.createClient({
  space: 'purcre4qggd1',
  environment: 'staging',
  accessToken: 'vdIxkgnr7AFvqwzg7XFaqNaI9K4LmXOwzuvAquQOxok'
});

class AdsArchive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            headline: '',
            introCopy: '',
            modules: [],
            desktopBG: '',
        };
    }

    componentDidMount() {
        document.title = 'Ad Solutions | Warner Bros. Discovery';

        client.getEntries({
            'content_type': 'archiveTemplate',
            'fields.slug': 'ad-solutions',
            'include': 10,
        }).then(data => {
            if (data) {
                // Top-level Page Tracking
                ReactGA.set({ page: '/ad-solutions' });
                ReactGA.pageview('/ad-solutions');

                // console.log('Archive', data);
                this.setState({
                    headline: data.items[0].fields.heading,
                    introCopy: data.items[0].fields.intro,
                    modules: data.items[0].fields.modules,
                    desktopBG: data.items[0].fields.heroBgImage,
                });
            }
        });
    }

    render() {
        const { headline, introCopy, desktopBG } = this.state;

        return (
            <div>
                <AdsHeader
                    size={'narrow'}
                    headline={headline}
                    introCopy={introCopy}
                    desktopBG={desktopBG}/>
                <br/><br/>
                <AdsNav showDescription={true}/>
            </div>
          );
    }
}

export default AdsArchive;
