import React, { Component } from 'react';
import ReactGA from 'react-ga';

import OpportunityModules from './OpportunityModules';
import OpportunityHeader from './OpportunityHeader';

var contentful = require('contentful')

var client = contentful.createClient({
  space: 'purcre4qggd1',
  environment: 'staging',
  accessToken: 'vdIxkgnr7AFvqwzg7XFaqNaI9K4LmXOwzuvAquQOxok'
});

class OpportunityArchive extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	    	headline: '',
	    	introCopy: '',
	    	modules: []
	    };
	}

	componentDidMount() {
		document.title = 'Hot Opportunities | Warner Bros. Discovery';

		client.getEntries({
			'content_type': 'archiveTemplate',
	    	'fields.slug': 'hot-opportunities',
	    	'include': 10
	    }).then(data => {
	    	if (data) {
	    		// console.log(data);
	    		// Top-level Page Tracking
				ReactGA.set({ page: '/hot-opportunities' });
  	    		ReactGA.pageview('/hot-opportunities');

	    		this.setState({
	    			desktopBG: data.items[0].fields.heroBgImage,
	    			headline: data.items[0].fields.heading,
	    			introCopy: data.items[0].fields.intro,
	    			modules: data.items[0].fields.modules,
	    			// subNavModules:  data.items[0].fields.content.fields.modules[0].fields.slides
	    		});
	    	}
	    });
	}

	render() {
		const { headline, introCopy, modules, desktopBG } = this.state;

		return (
		    <div>
				<OpportunityHeader
					desktopBG={desktopBG}
					headline={headline}
					introCopy={introCopy}/>

				<OpportunityModules modules={modules}/>
		    </div>
		  );
	}
}

export default OpportunityArchive;
