import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import SearchForm from './utility/SearchForm';

var contentful = require('contentful')

var client = contentful.createClient({
  space: 'purcre4qggd1',
  environment: 'staging',
  accessToken: 'vdIxkgnr7AFvqwzg7XFaqNaI9K4LmXOwzuvAquQOxok'
});

class Search extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
            searchResults: [],
            featuredSearchResults: [],
            noResults: false,
            noFeaturedResults: false,
        };
    }

    componentDidMount() {
        var str = window.location.href;
        var matches = str.match(/term=([^&]*)/);

        console.log('Match', matches);
        
        if (matches) {
            var searchTermVal = decodeURIComponent(matches[1].replace("+", "%20"));
            var searchTermNice = searchTermVal.replace("+", " ");
            
            document.title = 'Searched: ' + searchTermNice + ' | Warner Bros. Discovery';

            console.log('Search Val', searchTermVal);
            console.log('Search Nice', searchTermNice);
            // console.log('Search Replaced', searchTermReplaced);

            this.setState({
                searchTerm: searchTermNice,
            })

            // Set "Most Relevant" search results (Searches specific field) 
            client.getEntries({
                'order': 'sys.updatedAt',
                'fields.tags': searchTermVal,
                'content_type': 'caseStudyPage',
            }).then(data => {
                // console.log(data)
                if (data) {
                    // console.log('Tags Search Results: ', data);
                    if (data.total !== 0) {
                        this.setState({
                            featuredSearchResults: data.items,
                        });
                    }
                }
            }).catch(error => {
                // console.log('error', error)
            });

            // Set "Most Relevant" search results (Searches specific field) 
            client.getEntries({
                'order': 'sys.updatedAt',
                'fields.tags': searchTermVal,
                'content_type': 'brandPage',
            }).then(data => {
                // console.log(data)
                if (data) {
                    // console.log('Tags Search Results: ', data);
                    if (data.total !== 0) {
                        var joined = this.state.featuredSearchResults.concat(data.items);
                        this.setState({ featuredSearchResults: joined })
                    }
                }
            });

            // Hot Ops 
            client.getEntries({
                'order': 'sys.updatedAt',
                'fields.tags': searchTermVal,
                'content_type': 'opportunity',
            }).then(data => {
                // console.log(data)
                if (data) {
                    // console.log('Tags Search Results: ', data);
                    if (data.total !== 0) {
                        var joined = this.state.featuredSearchResults.concat(data.items);
                        this.setState({ featuredSearchResults: joined })
                    }
                }
            });

            // Ad Solutions
            client.getEntries({
                'order': 'sys.updatedAt',
                'fields.tags': searchTermVal,
                'content_type': 'adSolution',
            }).then(data => {
                // console.log(data)
                if (data) {
                    // console.log('Tags Search Results: ', data);
                    if (data.total !== 0) {
                        var joined = this.state.featuredSearchResults.concat(data.items);
                        this.setState({ featuredSearchResults: joined })
                    }
                }
            });

            // Search across all entries
            client.getEntries({
                'query': searchTermVal,
                'sys.contentType.sys.id[in]': 'brandPage,adSolution,opportunity,caseStudyPage,archiveTemplate,brandStudioPage,standardPage',
                'order': 'sys.updatedAt',
            }).then(data => {
                // console.log(data)
                if (data) {
                    // console.log('Search Results: ', data);
                    if (data.total !== 0) {
                        this.setState({
                            searchResults: data.items,
                        });
                        // console.log('Results', this.state.searchResults);
                    } else {
                        this.setState({
                            noResults: true,
                        })
                    }
                }
            }).catch(error => {
                console.log('error', error);
                this.setState({
                    noResults: true,
                })
            });
        }
    }

    render() {
        const itemsArr = [],
              featuredItems = [];
        const {searchTerm, searchResults, noResults, featuredSearchResults} = this.state;

        featuredSearchResults.map((item, index) => (
            item.sys.contentType.sys.id === 'brandPage' && (
                featuredItems.push(
                    <Link to={`brands/${item.fields.slug}`} className="mod__gallery--item small flex">
                        <div className="search-result__text">
                            <h2>{item.fields.title}</h2>
                            <p>{item.fields.heading}</p>
                        </div>
                    </Link>
                )
            ) || item.sys.contentType.sys.id === 'caseStudyPage' && (
                featuredItems.push(
                    <Link to={`case-studies/${item.fields.slug}`} className="mod__gallery--item small">
                        <div className="search-result__media">
                            <img src={item.fields.featuredImage.fields.file.url} 
                                 alt={item.fields.featuredImage.fields.file.fileName}/>
                            <h2>{item.fields.title}</h2>
                        </div>
                    </Link>
                )
            ) || item.sys.contentType.sys.id === 'opportunity' && (
                    featuredItems.push(
                        <a href={item.fields.url ? item.fields.url : '/hot-opportunities'} target={item.fields.url ? '_blank' : ''} className="mod__gallery--item small">
                            <div className="search-result__media">
                                <img src={item.fields.image.fields.file.url} 
                                     alt={item.fields.image.fields.file.url.fileName}/>
                                <h2>{item.fields.heading}</h2>
                            </div>
                        </a>
                    )
            ) || item.sys.contentType.sys.id === 'adSolution' && (
                    featuredItems.push(
                        <Link to={`ad-solutions/${item.fields.slug}`} className="mod__gallery--item small flex">
                            <div className="search-result__text">
                                <h2>{item.fields.title}</h2>
                                <p>{item.fields.shortDescription}</p>
                            </div>
                        </Link>
                    )
                )
            )
        )

        searchResults.map((item, index) => (
            item.sys.contentType.sys.id === 'brandPage' && (
                itemsArr.push(
                    <Link to={`brands/${item.fields.slug}`} className="mod__gallery--item small flex">
                        <div className="search-result__text">
                            <h2>{item.fields.title}</h2>
                            <p>{item.fields.heading}</p>
                        </div>
                    </Link>
                )
            ) || item.sys.contentType.sys.id === 'caseStudyPage' && (
                itemsArr.push(
                    <Link to={`case-studies/${item.fields.slug}`} className="mod__gallery--item small">
                        <div className="search-result__media">
                            <img src={item.fields.featuredImage.fields.file.url} 
                                 alt={item.fields.featuredImage.fields.file.fileName}/>
                            <h2>{item.fields.title}</h2>
                        </div>
                    </Link>
                )
            ) || item.sys.contentType.sys.id === 'opportunity' && (
                    itemsArr.push(
                        <a href={item.fields.url ? item.fields.url : '/hot-opportunities'} target={item.fields.url ? '_blank' : ''} className="mod__gallery--item small">
                            <div className="search-result__media">
                                <img src={item.fields.image.fields.file.url} 
                                     alt={item.fields.image.fields.file.url.fileName}/>
                                <h2>{item.fields.heading}</h2>
                            </div>
                        </a>
                    )
            ) || item.sys.contentType.sys.id === 'adSolution' && (
                    itemsArr.push(
                        <Link to={`ad-solutions/${item.fields.slug}`} className="mod__gallery--item small flex">
                            <div className="search-result__text">
                                <h2>{item.fields.title}</h2>
                                <p>{item.fields.shortDescription}</p>
                            </div>
                        </Link>
                    )
                )
            )  || item.sys.contentType.sys.id === 'archiveTemplate' && (
                    itemsArr.push(
                        <Link to={`${item.fields.slug}`} className="mod__gallery--item small flex">
                            <div className="search-result__text">
                                <h2>{item.fields.title}</h2>
                                <p>&nbsp;</p>
                            </div>
                        </Link>
                    )
            )  || item.sys.contentType.sys.id === 'brandStudioPage' && (
                itemsArr.push(
                    <Link to={`${item.fields.slug}`} className="mod__gallery--item small flex">
                        <div className="search-result__text">
                            <h2>{item.fields.title}</h2>
                            <p>&nbsp;</p>
                        </div>
                    </Link>
                )
            ) || item.sys.contentType.sys.id === 'standardPage' && (
                itemsArr.push(
                    <Link to={`${item.fields.slug}`} className="mod__gallery--item small flex">
                        <div className="search-result__text">
                            <h2>{item.fields.title}</h2>
                            <div dangerouslySetInnerHTML={{__html: item.fields.intro}}/>
                        </div>
                    </Link>
                )
            )

            
        )

        return (
            <div>
                <section className={`section__intro`}>
                    <div className="container">
                        <h1 className="section__intro--headline">Search: {searchTerm}</h1>
                        <SearchForm size="small"/>
                    </div>
                </section>
                <div className="container">
                    {noResults && (
                        <div className="search-wrapper">
                            <h1>No results for the term "{searchTerm}"</h1>
                        </div>
                    )}
                    {!noResults && (
                        <div className="search-wrapper">
                            {featuredItems.length >= 1 && (
                                <div>
                                    <h2 className="search-headline">Most relevant results</h2>
                                    <div className="mod__gallery">
                                        {featuredItems}
                                    </div>
                                    <br/><br/>
                                    <h2 className="search-headline">All search results</h2>
                                </div>
                            )}
                            

                            <div className="mod__gallery">
                                {itemsArr}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Search;
