import React, { Component } from 'react';
import ReactGA from 'react-ga';

import Vimeo from '@u-wave/react-vimeo';

var contentful = require('contentful')

var client = contentful.createClient({
  space: 'purcre4qggd1',
  environment: 'staging',
  accessToken: 'vdIxkgnr7AFvqwzg7XFaqNaI9K4LmXOwzuvAquQOxok'
});

class Homepage extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	    	headline: '',
	    	introCopy: '',
	    	whiteLogo: '',
	    	bgImage: '',
	    	brands: [],
	    	tiles: []
	    };

	}

	componentDidMount() {
		document.title = 'Warner Bros. Discovery: CNN, CNN Business, HLN';

		// Top-level Page Tracking
		ReactGA.set({ page: '/' });
  		ReactGA.pageview('/');

		client.getEntries({
			content_type: 'homePage',
	    	'fields.slug': '/',
	    	'include': 10
	    }).then(data => {
	    	if (data) {
	    		this.setState({
	    			headline: data.items[0].fields.heading,
	    			introCopy: data.items[0].fields.headerCopy,
	    			whiteLogo: data.items[0].fields.warnerMediaLogo.fields.file.url,
	    			bgImage: data.items[0].fields.headerBackgroundImage.fields.file.url,
	    			brands: data.items[0].fields.brandLogos,
	    			tiles: data.items[0].fields.homePageTiles
	    		});
	    	}
	    });
	}

	handleClick(label) {
	    ReactGA.event({
	      category: 'Homepage',
	      action: 'Click',
	      label: 'Tile - ' + label
	    });
	}

	render() {
		const { headline, introCopy, whiteLogo, bgImage, brands, tiles } = this.state;
		let tilesAmount = '';

		let introStyles = {
			backgroundImage: 'url(' + bgImage + ')'
		}

		if (tiles.length === 5) {
			tilesAmount = 'five';
		}
		
		return (
			<div>
			    <header className="homepage__intro" style={introStyles}>
			    	<div className="container">
			    		<div className="homepage__flex">
			    			<div className="homepage__flex--left">
					    		<h1 className="homepage__intro--headline">{headline}</h1>
					    		<div className="homepage__intro--copy" dangerouslySetInnerHTML={{ __html: introCopy }}/>
					    	</div>

					    	<div className="homepage__flex--right">
					    		{ /* <img className="homepage__intro--logo" src={whiteLogo} alt="Warner Media Logo"/> */ }
					    		<img className="homepage__intro--logo" src="/assets/images/WBD_HorizontalLogo_OnDark_White.png" alt="Warner Media Logo"/>
					    		<div className="homepage__brands">
					    			{brands.map((item, index) => (
						    			<span className="homepage__brands--item" key={index}>
					    					<img src={item.fields.file.url} alt={item.fields.file.fileName}/>
					    				</span>
						    		))}
					    		</div>
					    	</div>
					    </div>
			    	</div>
			    </header>
			    <section className="hp-tiles">
			    	<div className="container--wide">
			    		{/* Add class 'five' to flex wrapper for the 5 point layout, otherwise the grid is 3x3 space between */}
			    		<div className={`hp-tiles__flex ${tilesAmount}`}>
			    			{tiles.map((item, index) => (
		    					<div className="hp-tiles__item" style={{backgroundColor: item.fields.tileColor}} key={index}>
		    						<a href={item.fields.url} className="hp-tiles__link" target={item.fields.openInNewWindow ? '_blank' : ''} onClick={() => {this.handleClick(item.fields.title)}}>&nbsp;</a>
		    						<div className={`hp-tiles__item--content ${item.fields.copyColor}`}>
				    					{item.fields.backgroundAsset && (
				    						item.fields.backgroundAsset.fields.assetType === false && (
				    							<img src={item.fields.backgroundAsset.fields.asset.fields.file.url} alt={`Homepage - ${item.fields.title}`}/>
				    						)
				    					)}

				    					{item.fields.backgroundAsset && (
					    					item.fields.backgroundAsset.fields.assetType === true && (
				    							<div className="hp-tiles__item--video">
				    							<Vimeo
                                                    video={item.fields.backgroundAsset.fields.vimeoID}
                                                    responsive={true}
                                                    controls={false}
                                                    muted={true}
                                                    loop={true}
                                                    background={true}
                                                    autoplay={true}/>
                                                </div>
					    					)
					    				)}
				    					
				    					<h2 className="hp-tiles__item--headline">{item.fields.title}</h2>
				    					
				    					{item.fields.caption && (
				    						<h3 className="hp-tiles__item--caption">{item.fields.caption}</h3>
				    					)}
				    				</div>
		    					</div>
			    			))}	
			    		</div>
			    	</div>
			    </section>
		    </div>
		  );
	}
}

export default Homepage;
